@tailwind base;
@tailwind components;
@tailwind utilities;

.login-body {
    font-size: 15px;

    .login-background {
        width: 100%;
        height: 100%;
        max-width: 100% !important;
    }

    .login-title {
        font-size: 30px;
        text-transform: uppercase;
        font-weight: bold;
        color: #1d75f9;
        margin-bottom: 50px;
    }

    .login-remember {
        width: 100%;
        display: flex;
        align-items: center;

        label {
            margin-left: 10px;
        }
    }

    .login-button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 36px;
        border-radius: 20px;
        background: #1d75f9;
        border: none;
        color: #fff;
        width: 255px;
        font-size: 16px;
        line-height: 34px;
        margin-top: 50px;

        &:focus {
            outline: none;
        }
    }

    .login-input {
        border-bottom: 2px solid #1d75f9;
        margin-bottom: 30px;
        padding-bottom: 5px;
        width: 100%;
        display: flex;
        align-items: center;

        i {
            font-size: 18px;
            color: #1d75f9;
            margin-right: 10px;
            margin-left: 10px;
        }

        input {
            border: 0;
            flex: 1;
            box-shadow: none;
            outline: none;
        }
    }
}

.container-grid {
    .loading {
        height: unset !important;
    }
}

.color-field-select {
    .field-wrap {
        border: 1px solid #fff;

        .time-widget__control {
            font-weight: 500;

            .time-widget__placeholder {
                color: #ffffff !important;
            }

            .time-widget__single-value {
                color: #ffffff !important;
            }

            background-color: transparent;

            .time-widget__indicators {
                .icon-remove {
                    padding-right: 10px !important;
                }

                .wrap-con-field {
                    .icon-field {
                        color: #ffffff !important;
                    }
                }
            }
        }

        .time-widget__menu {
            color: #333;
        }
    }

    .icon-remove {
        width: 10px !important;
    }

    background-color: transparent;

    .singleMonth-picker {
        border: 1px solid #fff;
        background: transparent;

        .icon-remove {
            padding-right: 10px !important;
        }

        .month-picker-input {
            background: transparent;
            color: #ffffff !important;
        }

        .wrap-con-field {
            color: #ffffff !important;

            .icon-field {
                color: #ffffff !important;
            }
        }
    }
}

.color-field-select-1 {
    background-color: transparent !important;
    padding-top: 15px;

    .input-field {
        color: #fff !important;
        background-color: transparent !important;

        .treeSelect {
            color: #fff !important;
        }

        .field-wrap {
            width: 250px;
            border: 1px solid #fff !important;
            background-color: transparent !important;

            .rc-tree-select {
                color: #fff !important;
                background-color: transparent !important;

                .rc-tree-select-selection--single {
                    background-color: transparent !important;
                    color: white !important;

                    .rc-tree-select-arrow {
                        .wrap-con-field {
                            .icon-field {
                                color: #fff !important;
                            }

                            .icon-field-line {
                                border-left: 1px solid #fff !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

//switch user style
.container-switch-user {
    .ant-drawer-content-wrapper {
        top: 0 !important;

        .ant-drawer-header {
            display: none;
            padding: 10px 24px;

            .ant-drawer-close {
                width: 43px;
                height: 43px;
                line-height: 43px;
            }
        }

        .ant-drawer-body {
            padding: 10px 20px;

            .container-switch-user {
                .container-info {
                    margin-bottom: 15px;
                    border-bottom: 1px solid #ccc;
                    padding: 0 0 15px 0;

                    .header-avatar {
                        display: flex;
                        flex-direction: row;

                        >img {
                            max-width: 90px;
                        }

                        .text-info {
                            flex: 1;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-end;
                            margin-left: 20px;
                        }

                        .tool-bar {
                            display: flex;
                            flex-direction: row;
                            justify-content: flex-end;

                            .ant-btn {
                                margin-left: 5px;
                            }

                            .change-password {
                                background: #ffa63d;
                                border: 1px solid #ffa63d;
                                color: #fff;

                                &:hover {
                                    background: #fff;
                                    color: #ffa63d;
                                }
                            }

                            .log-out {
                                background: #ff4e4e;
                                border: 1px solid #ff4e4e;
                                color: #fff;

                                &:hover {
                                    background: #fff;
                                    color: #ff4e4e;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .idtek-fieldset {
        >legend {
            margin: 0;
            padding: 5px !important;
            text-transform: uppercase;
        }

        .action-rows {
            >.ag-react-container {
                display: flex;
                justify-content: center;

                .action-btn-switch {
                    background-color: #37a4c3;

                    &:hover {
                        background: #fff;
                        color: #37a4c3 !important;
                        border-color: #37a4c3;
                    }
                }
            }
        }

        .ag-header-row {
            .header-action {
                .ag-cell-label-container {
                    display: none;
                }
            }
        }

        .buttons {

            // justify-content: flex-end !important;
            .save {
                background: #0088fe;
                color: #fff;
                border: 1px solid #0088fe;
                border-radius: 32px;
                text-transform: initial;

                &:hover {
                    background: #fff;
                    color: #0088fe;
                }
            }
        }
    }
}

.home-page {
    width: 100%;

    .content-page {
        display: flex;
        overflow: hidden;
        flex: 1 1;
        background: -webkit-gradient(linear, left top, left bottom, from(#00b8ff), color-stop(38.54%, hsla(0, 0%, 87.1%, 0.36)));
        background: -webkit-linear-gradient(top, #00b8ff, hsla(0, 0%, 87.1%, 0.36) 38.54%);
        background: linear-gradient(180deg, #00b8ff, hsla(0, 0%, 87.1%, 0.36) 38.54%);
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        display: flex;
        align-items: center;
        flex-direction: column;
        overflow: auto;

        .header-content {
            width: 100%;
            height: 71px;
            background: #21c1ff;

            .box-menu {
                width: 95px;
                height: 71px;
                background: #21c1ff;
                margin: 0px;

                &.actived {
                    background: #00a3ff;
                }

                &:hover {
                    background: #00a3ff;
                }

                >img {
                    height: 18px;
                    width: 21px;
                    text-align: center;
                    padding: 2px 0 0;
                    margin: auto;
                    margin-top: 10px;
                }

                >span {
                    font-size: 12px;
                    margin-top: 6px;
                    text-transform: uppercase;
                }
            }
        }

        .body-content.trang-chu {
            color: #000;
            display: flex;
            flex: 1 1 0%;
            width: 100%;
            align-items: stretch;
            background: rgb(255, 255, 255);
            padding: 15px 5%;

            .content-left {
                flex: 1;
                display: flex;
                flex-direction: column;
                margin-right: 35px;

                .content-top {
                    .content-news {
                        display: flex;
                        flex-direction: column;

                        li {
                            list-style: none;
                        }

                        .news-detail {
                            flex: 1;
                            display: flex;
                            padding: 15px 10px;

                            .image {
                                width: 150px;

                                img {
                                    width: 100%;
                                }
                            }

                            .detail {
                                flex: 1;
                                padding: 5px 10px;

                                .detail-title {
                                    font-weight: bold;
                                    font-size: 13px;
                                    line-height: 16px;
                                    margin-bottom: 5px;
                                    cursor: pointer;

                                    &:hover {
                                        color: #0454b3;
                                    }
                                }

                                .detail-content {
                                    font-size: 12px;
                                    line-height: 16px;
                                }
                            }
                        }

                        .border-line {
                            text-align: right;
                            border-bottom: 1px solid #3bc4fa;
                            font-size: 11px;
                            line-height: 13px;
                            padding-bottom: 2px;
                            color: #0454b3;
                            padding-right: 10px;
                        }
                    }
                }

                .content-bottom {
                    // flex: 1;
                    padding: 15px 0px 5px 0px;

                    .title-tin-tuc-khac {
                        font-size: 12px;
                        line-height: 14px;
                        text-decoration-line: underline;
                        color: #000000;
                        // cursor: pointer;
                        font-weight: bold;

                        &:hover {
                            // color: #0454b3;
                        }
                    }

                    .content {
                        margin: 10px;

                        li {
                            font-size: 12px;
                            line-height: 20px;
                            color: #000;
                            list-style: none;

                            &::before {
                                content: "\2022";
                                color: #39c3fa;
                                font-weight: bold;
                                display: inline-block;
                                width: 1em;
                                margin-left: -1em;
                                font-size: 16px;
                            }

                            span {
                                cursor: pointer;

                                &:hover {
                                    color: #0454b3;
                                }
                            }
                        }
                    }

                    .xem-them {
                        padding-top: 15px;
                        font-weight: bold;
                        cursor: pointer;
                        color: #595959;
                        font-size: 12px;
                        line-height: 14px;

                        &:hover {
                            color: #096dd9;
                        }
                    }
                }

                .content-left-bottom {
                    flex: 1;
                    padding: 0px 0px 5px 0px;

                    .content-right-top {
                        .thong-tin-chia-se-list {
                            padding-top: 5px;

                            li.file-box {
                                list-style: none;
                                float: left;
                                width: 100%;
                                display: flex;
                                padding: 10px 0px 0;
                                position: relative;

                                .file-box-date {
                                    width: 39px;
                                    float: left;
                                    text-align: center;
                                    color: #999;
                                    position: relative;
                                    margin-right: 15px;

                                    label.big {
                                        padding-top: 3px;
                                        width: 100%;
                                        background: #c5eefe;
                                        margin-bottom: 0px;
                                        font-weight: 800;
                                        font-size: 18px;
                                        line-height: 22px;
                                    }

                                    span {
                                        float: left;
                                        width: 100%;
                                        background: #c5eefe;
                                        padding-bottom: 8px;
                                        font-weight: 300;
                                        margin-top: -2px;
                                        font-size: 8px;
                                        line-height: 10px;
                                    }

                                    &:after {
                                        content: "";
                                        display: block;
                                        position: absolute;
                                        background-color: #fff;
                                        top: 36px;
                                        right: 0px;
                                        height: 7px;
                                        width: 7px;
                                        transform: rotate(0deg);
                                        background: #ffffff;
                                        background: #f6f6f6;
                                        background: -moz-linear-gradient(-45deg, #f6f6f6 -10%, #49c7f9 51%, #ffffff 50%, #ffffff 100%);
                                        background: -webkit-linear-gradient(-45deg, #f6f6f6 -10%, #49c7f9 51%, #ffffff 50%, #ffffff 100%);
                                        background: linear-gradient(135deg, #f6f6f6 -10%, #49c7f9 51%, #ffffff 50%, #ffffff 100%);
                                        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f6f6f6', endColorstr='#ffffff', GradientType=1);
                                    }
                                }

                                .file-box-content {
                                    flex: 1;

                                    .file-box-title {
                                        span {
                                            font-size: 12px;
                                            line-height: 14px;
                                            vertical-align: top;
                                            color: #000;
                                            padding-right: 3px;

                                            &:hover {
                                                color: #0454b3;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .content-right {
                // flex: 1;
                padding: 0px 0px 5px 0px;
                display: flex;
                width: 40%;

                .content-right-top {
                    .thong-tin-chia-se-list {
                        padding-top: 5px;

                        li.file-box {
                            list-style: none;
                            float: left;
                            width: 100%;
                            display: flex;
                            padding: 10px 0px 0;
                            position: relative;

                            .file-box-date {
                                width: 39px;
                                float: left;
                                text-align: center;
                                color: #999;
                                position: relative;
                                margin-right: 15px;

                                label.big {
                                    padding-top: 3px;
                                    width: 100%;
                                    background: #c5eefe;
                                    margin-bottom: 0px;
                                    font-weight: 800;
                                    font-size: 18px;
                                    line-height: 22px;
                                }

                                span {
                                    float: left;
                                    width: 100%;
                                    background: #c5eefe;
                                    padding-bottom: 8px;
                                    font-weight: 300;
                                    margin-top: -2px;
                                    font-size: 8px;
                                    line-height: 10px;
                                }

                                &:after {
                                    content: "";
                                    display: block;
                                    position: absolute;
                                    background-color: #fff;
                                    top: 36px;
                                    right: 0px;
                                    height: 7px;
                                    width: 7px;
                                    transform: rotate(0deg);
                                    background: #ffffff;
                                    background: #f6f6f6;
                                    background: -moz-linear-gradient(-45deg, #f6f6f6 -10%, #49c7f9 51%, #ffffff 50%, #ffffff 100%);
                                    background: -webkit-linear-gradient(-45deg, #f6f6f6 -10%, #49c7f9 51%, #ffffff 50%, #ffffff 100%);
                                    background: linear-gradient(135deg, #f6f6f6 -10%, #49c7f9 51%, #ffffff 50%, #ffffff 100%);
                                    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f6f6f6', endColorstr='#ffffff', GradientType=1);
                                }
                            }

                            .file-box-content {
                                flex: 1;

                                .file-box-title {
                                    span {
                                        font-size: 12px;
                                        line-height: 14px;
                                        vertical-align: top;
                                        color: #000;
                                        padding-right: 3px;

                                        &:hover {
                                            color: #0454b3;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .content-right-bottom {
                    flex: 1;
                    margin-top: 0px;

                    .chung-nhan-content {
                        padding-top: 10px;
                    }
                }
            }
        }

        .body-content.thong-tin-chia-se {
            color: #000;
            display: flex;
            flex: 1 1 0%;
            width: 100%;
            align-items: stretch;
            background: rgb(255, 255, 255);
            padding: 15px 70px;

            .search-field {
                flex: 1;

                .content-search {
                    padding: 5px;
                    margin-bottom: 15px;

                    .search-content {
                        display: flex;
                        justify-content: center;

                        .input {
                            width: 62%;
                            height: 32px;
                            border: unset !important;
                            border-bottom: 1px solid #0093d9 !important;

                            .input-search {
                                width: 93%;
                                border: 0;
                                line-height: 30px;
                                float: left;
                                padding: 0 10px;
                                outline: none;
                                border-radius: 30px;
                            }

                            .i-close {
                                height: 30px;
                                width: 30px;
                                background-size: 65%;
                                background-repeat: no-repeat;
                                background-position: 50%;
                                border: none;
                                -webkit-transition: 0.3s;
                                transition: 0.3s;
                                font-size: 18px !important;
                                color: #fff !important;
                            }

                            i {
                                cursor: pointer;
                                display: table-cell;
                                color: #0093d9 !important;
                                font-size: 20px !important;
                                line-height: 28px !important;
                                border-left: unset !important;
                                background: unset !important;
                                border-bottom-right-radius: unset !important;
                                border-top-right-radius: unset !important;
                            }
                        }

                        .button {
                            .btn {
                                background-color: #fff;
                                border-color: #2e7d32;
                                padding: 3px 8px;
                                color: #2e7d32;
                                font-size: 12px;
                                line-height: 22px;
                                margin-left: 10px;
                                float: left;
                            }
                        }
                    }
                }
            }
        }
    }
}

.button-file {
    padding: 0px 12px 8px 12px;
    height: 26px !important;
    width: 26px !important;
    display: flex;

    background-color: #03cafc !important;
    border-color: #0389ac !important;

    i,
    span {
        color: #ffffff !important;
    }

    &:hover {
        border-color: #0389ac !important;
        background: #ffffff !important;

        i,
        span {
            color: #0389ac !important;
        }
    }
}

.menu-grid {
    .text-align-center {
        text-align: center;
    }
}

.global-flex-justify-start {
    display: flex !important;
    justify-content: flex-start !important;
}

.global-flex-justify-end {
    display: flex !important;
    justify-content: flex-end !important;
}

.global-flex-align-center-justify-end {
    display: flex !important;
    justify-content: flex-end !important;
    height: 100% !important;
    align-items: center !important;
}

.global-flex-justify-center {
    display: flex !important;
    justify-content: center !important;
}

.global-flex-direction-column {
    display: flex !important;
    flex-direction: column;
}

.global-flex-align-center {
    display: flex !important;
    align-items: center !important;
    height: 100% !important;
}

.global-flex-justify-align-center {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    height: 100% !important;
}

.global-flex-column {
    flex-direction: column !important;
}

.global-mgt-10 {
    margin-top: 10px;
}

.global-mgb-10 {
    margin-bottom: 10px;
}

.global-mgl-10 {
    margin-left: 10px;
}

.global-mgr-10 {
    margin-right: 10px;
}

.global-text-bold {
    font-weight: bold;
}

.global-flex {
    display: flex !important;
}

.global-flex-2 {
    flex: 2;
}

.global-flex-1 {
    flex: 1;
}

.global-mg-auto {
    margin: auto;
}

.global-relative {
    position: relative;
}

.fieldset-collapsed {
    padding: revert !important;
    height: 30px !important;
    border: 0 !important;
    border-top: 1px solid #ddd !important;
    border-radius: 0 !important;
    overflow: hidden !important;
}

.ptw-fieldset-filter {
    background: #f3f6f8 !important;
    padding-bottom: 0px;
    padding-bottom: 5px;

    >legend {
        padding: 2px 10px !important;
        color: #036db3 !important;
        text-transform: capitalize;
        font-size: 13px !important;
        text-transform: uppercase;
        // margin-bottom: 0;
    }

    form {
        .row-field {
            .input-field {
                .error-field {
                    height: 10px;
                }
            }
        }
    }
}

.ant-steps:not(.ant-steps-dot):not(.ant-steps-navigation):not(.ant-steps-vertical) .ant-steps-item {
    padding-top: 0px !important;
}

.custom-fullscreen {
    .btn-fullscreen-custom {
        //width: 30px;
        //height: 30px;
        border-radius: 3px;
        border: 1px solid #ccc;
        cursor: pointer;
        outline: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 5px;
        border-color: #ccc;
        background-color: #fff;

        &:hover {
            color: #007bff;
            cursor: pointer;
            border-color: #007bff;
        }
    }
}

.full-screen-display-none-ptw-risk {
    //padding: 15px;
    overflow-y: auto;

    .header-steps {
        display: none !important;
    }

    .style-fixed-header {
        display: none !important;
    }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

.full-browser {
    padding: 10px !important;
}

.modal-fullscreen {
    top: 0px !important;
    margin: 0 !important;
    height: 100%;

    .rc-dialog-content {
        height: 100%;

        .rc-dialog-body {
            height: 100%;
        }
    }
}

.popup-in-dashboard {
    .rc-dialog-body {
        min-height: 600px !important;
    }
}

// .rc-select-single:not(.rc-select-customize-input) .rc-select-selector {
//     z-index: 1 !important;
// }

.idbeauty-setting-fieldset {
    >legend {
        font-size: 20px !important;
        font-weight: 600;
        margin-bottom: 15px !important;
    }
}

.id-modal {
    .rc-dialog-header {
        .anticon-close {
            color: var(--main-color-blue-ocean) !important
        }

        .anticon {
            color: unset;
        }
    }
}

.idtek-grid-loading {
    right: 0px !important;
    bottom: 1px !important;
    left: 0px !important;
    z-index: 4 !important;
}

.create-random-code {
    .read-only {
        .body-wrap:hover {
            border-color: #ccc !important;
            box-shadow: none !important;
        }

        .wrap-con-field {
            cursor: pointer;
            pointer-events: all;

            .anticon {
                margin-bottom: 5px !important;
            }
        }
    }
}

.filter-top {
    display: flex;
    align-items: center;

    .label {
        font-size: 13px;
        margin-right: 10px;
        font-weight: 600;
    }

    .ml5 {
        margin-left: 10px;
    }
}

.ant-menu-title-content {
    height: 100%;
}

.field-array-custom {
    display: flex;
    width: 100%;
    margin-bottom: 10px;

    .content {
        width: 100%;

        .button-review {
            margin-bottom: 10px;
        }

        .row-content {
            align-items: center;
            display: flex;
            width: 600px;

            .label-children {
                margin-bottom: 20px;
            }

            .input {
                flex: 1;
                display: flex;

                .time-picker {
                    .row-field {
                        width: 90%;
                    }

                    // .label-field {
                    //   display: none;
                    // }
                }
            }

            .action-btn-delete {
                height: 28px;
                width: 28px;
                justify-content: center;
                margin-bottom: 20px;
            }
        }
    }

    .label {
        display: flex;
    }
}

.link-underline {
    text-decoration: underline;
    color: #40a9ff;

    &:hover {
        cursor: pointer;
    }
}

.container-row {
    margin-right: -15px;
    margin-left: -15px;

    .container-cell {
        padding-right: 15px;
        padding-left: 15px;
        overflow: hidden;
    }
}

.item-separator {
    color: grey;
    padding: 0 8px;
}

.ag-theme-balham {
    .ag-row.ag-row-last {
        border-bottom-width: 0px !important;
    }
}

//dùng cho table filed bị lệch vì core cũ vẽ không đúng
//nếu bỏ cái này thì kiểm tra các view nhập hàng trong kho
.id-table-field-container {
    .id-table-field-header {
        border-left: none !important;
    }

    .id-table-field-body {
        overflow-y: scroll !important;

    }

    .id-table-horizotal-scroll {
        display: none;
    }

    .id-table-field-body::-webkit-scrollbar {
        width: 10px !important;
    }

}