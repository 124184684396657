$d: 1.2em;
$t: 2.5s;

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  color: #fff;
  text-align: center;
  width: 100%;
  height: 100%;
  padding: 10px;
  margin: 0;
  .ant-spin {
    .ant-spin-dot {
      .ant-spin-dot-item {
        background-color: #ff5722;
      }
    }
    .ant-spin-text {
      color: #777;
      font-size: 13px;
    }
  }
}

.dot {
  background: #0abeff;

  &,
  &:after {
    display: inline-block;
    width: $d;
    height: $d;
    border-radius: 50%;
    animation: a $t calc(((var(--i) + var(--o, 0)) / var(--n) - 1) * #{$t}) infinite;
  }

  &:after {
    --o: 1;
    background: currentcolor;
    content: "";
  }
}

@keyframes a {
  0%,
  50% {
    transform: scale(0);
  }
}
