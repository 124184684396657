.base-tree-grid.ag-theme-balham {
  .ag-row {
    .ag-cell {
      .ag-cell-wrap-edit {
        .anticon-arrow-up,
        .anticon-arrow-down,
        .anticon-copy,
        .anticon-edit {
          cursor: pointer;
          color: #333;
          font-size: 14px;

          &:hover {
            color: #007bff !important;
          }
        }

        .ag-cell-icons {
          display: none;
        }
      }

      &:hover {
        .ag-cell-icons {
          display: flex;
          margin-left: 10px;
        }
      }
    }
  }
}


